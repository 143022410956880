<template>
  <div>
    <div class="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all
              duration-500 rounded p-1 mt-5 w-full">
      <div class="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
        <p>
          <label :for="name" class="bg-white text-gray-600 px-1">{{ label }}
            <span class="text-red-500">*</span></label>
        </p>
      </div>
      <div class="flex flex-row">
        <input :readonly="true" type="hidden" :name="name" :placeholder="placeholder"
               :autocomplete="autocomplete"
               v-model="$v.inputValue.$model" :class="inputClass"
             @input="$emit('input', inputValue)" class="py-1 px-1 text-gray-900 outline-none
                         block h-full w-full" />
        <input @click="toggleSelectList = !toggleSelectList" :readonly="true" type="text"
               v-model="showValue" class="py-1 px-1 text-gray-900
        outline-none block h-full w-full" />
        <div class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l items-center border-gray-200
        svelte-1l8159u">
          <span @click="toggleSelectList = !toggleSelectList" class="cursor-pointer w-6 h-6
          text-gray-600 outline-none focus:outline-none">
            <svg v-if="toggleSelectList" class="w-4 h-4 mt-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" /></svg>
            <svg  v-if="!toggleSelectList" class="w-4 h-4 mt-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" /></svg>
          </span>
        </div>
      </div>
      <div v-if="toggleSelectList" class="cursor-pointer origin-top-left absolute mt-2 w-2/5 -ml-1
      rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50">
        <span v-for="option in options" @click="inputValue = option.db;
        toggleSelectList = !toggleSelectList" :key="option.db"
              class="block px-4 py-2 text-sm text-gray-700
                hover:bg-gray-100">{{option.name}}</span>
      </div>
    </div>
    <div class="error" v-if="$v.inputValue.$error">
      <span class="d-block" v-if="!$v.inputValue.requiredIf">{{ requiredMsg }}</span>
      <span class="d-block" v-if="!$v.inputValue.minLength">{{ minLengthMsg }}</span>
<!--      <span class="d-block" v-if="!$v.inputValue.mustBeCool">-->
<!--          Eingabe stimmt nicht überein!</span>-->
    </div>
  </div>
</template>

<script>
import { requiredIf, minLength /* helpers */ } from 'vuelidate/lib/validators';

// const contains = (param) => helpers.withParams({ type: 'contains', value: param },
//   (value) => !helpers.req(value) || value === param);

export default {
  name: 'Select',
  props: {
    name: {
      type: String,
      required: true,
      default: 'Input',
    },
    placeholder: {
      type: String,
      default: '',
    },
    val: String,
    mustBe: String,
    inputClass: String,
    inputType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: 'Mein Label',
    },
    options: {
      type: Array,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    requiredMsg: {
      type: String,
      default: 'Das ist ein Pflichtfeld.',
    },
    minLength: {
      type: Number,
      default: 0,
    },
    minLengthMsg: String,
  },
  data() {
    return {
      inputValue: '',
      toggleSelectList: false,
      showValue: '',
    };
  },
  validations() {
    return {
      inputValue: {
        // eslint-disable-next-line no-self-compare,func-names
        requiredIf: requiredIf(() => true),
        minLength: minLength(4),
        // mustBeCool: contains(this.mustBe),
      },
    };
  },
  methods: {
    setName() {
      this.options.forEach((o) => {
        if (o.db === this.inputValue) {
          this.showValue = o.name;
        }
        return '';
      });
    },
  },
  mounted() {
  },
  watch: {
    val: {
      immediate: true,
      handler(val) {
        this.inputValue = val;
        this.setName();
      },
    },
    '$v.inputValue.$error': {
      handler(value) {
        this.$emit('error', value);
      },
      deep: true,
    },
    inputValue(value) {
      this.$emit('input', value);
      this.setName();
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 12px;
}
</style>
