<template>
  <div class="flex-1 flex flex-col block h-0.5 w-full divide-y divide-gray-200">
    <div class="flex-none flex flex-row mt-1 w-full">
      <div class="flex-none w-16 text-gray-700 font-semibold font-sans text-sm
                      tracking-wide">
        Anz.
      </div>
      <div class="flex-1 text-gray-700 font-semibold font-sans text-sm
                      tracking-wide">
        Bezeichnung
      </div>
    </div>
    <div class="flex-grow flex flex-col w-full min-h-24 overflow-auto z-10 pb-5
    divide-y divide-gray-200">
      <div v-for="item in orderItems" :key="item.id"
           class="flex flex-row p-2">
        <div class="flex-none w-16 text-gray-700 font-sans text-sm text-left
                          align-top tracking-wide">
          <span v-if="!selectable">{{ item.pl_quantity }}</span>
          <FormulateInput
            v-if="selectable"
            input-class="ml-2 transform scale-150"
            label=""
            type="checkbox"
            :value="item"
            v-model="slotOrderItems"
          />
<!--          <input v-if="selectable" type="number" class="p-1 border
focus-within:border-blue-500-->
<!--          focus-within:text-blue-500 transition-all duration-500 rounded w-12 outline-none"-->
<!--                 :value="item.pl_quantity" min="0" :max="item.pl_quantity" />-->
        </div>
        <div class="flex-1 text-gray-700 font-sans text-sm tracking-wide"
             v-html="getItemDescription(item)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductTable',
  props: {
    card: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slotOrderItems: [],
      orderItems: [],
    };
  },
  computed: {
    // orderItems() {
    //   const items = [];
    //   this.card.order_items.forEach((o) => {
    //     if (o.pl_type !== 6) {
    //       // eslint-disable-next-line no-param-reassign
    //       o.pl_name = o.pl_name.replace(/\s\|\s/g, '<br />');
    //       items.push(o);
    //     }
    //   });
    //   return items;
    // },
  },
  methods: {
    getOrderItems() {
      this.$axios.get(`cards/${this.card.id}/order-items`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          // const items = [];
          // response.data.data.forEach((o) => {
          //   if (o.pl_type !== 6) {
          //     // eslint-disable-next-line no-param-reassign
          //     o.pl_name = o.pl_name.replace(/\s\|\s/g, '<br />');
          //     items.push(o);
          //   }
          // });
          this.orderItems = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    getItemDescription(item) {
      let result = item.pl_name;
      if (item.pl_image !== null) {
        result = `${item.pl_name} <br /><img src="${item.pl_image}" v-if="item.pl_image !== null" width="100%" />`;
        return result;
      }
      if (item.order_return_item) {
        result = `<span class="text-yellow-500"><svg width="15px" class="inline" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M629.657 343.598L528.971 444.284c-9.373 9.372-24.568 9.372-33.941 0L394.343 343.598c-9.373-9.373-9.373-24.569 0-33.941l10.823-10.823c9.562-9.562 25.133-9.34 34.419.492L480 342.118V160H292.451a24.005 24.005 0 0 1-16.971-7.029l-16-16C244.361 121.851 255.069 96 276.451 96H520c13.255 0 24 10.745 24 24v222.118l40.416-42.792c9.285-9.831 24.856-10.054 34.419-.492l10.823 10.823c9.372 9.372 9.372 24.569-.001 33.941zm-265.138 15.431A23.999 23.999 0 0 0 347.548 352H160V169.881l40.416 42.792c9.286 9.831 24.856 10.054 34.419.491l10.822-10.822c9.373-9.373 9.373-24.569 0-33.941L144.971 67.716c-9.373-9.373-24.569-9.373-33.941 0L10.343 168.402c-9.373 9.373-9.373 24.569 0 33.941l10.822 10.822c9.562 9.562 25.133 9.34 34.419-.491L96 169.881V392c0 13.255 10.745 24 24 24h243.549c21.382 0 32.09-25.851 16.971-40.971l-16.001-16z"></path></svg> Reklamation vorhanden </span><br /> ${result}`;
      }
      return result;
    },
  },
  beforeMount() {
    this.getOrderItems();
  },
  watch: {
    slotOrderItems: {
      deep: true,
      handler(value) {
        this.$emit('addSlotOrderItems', value);
      },
    },
  },
};
</script>

<style scoped>

</style>
