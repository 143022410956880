<template>
    <button class="rounded text-gray-100 px-3 py-1
          hover:shadow-inner align-middle inline-flex items-center
          transition-all duration-300"
          :class="[
            load === 'initial' ? initialClass : '',
            load === 'loading' ? loadClass: '',
            load === 'success' ? successClass: '',
            load === 'error' ? errorClass: '']"
            :disabled="load !== 'initial'">
        <span v-if="load === 'initial'">{{onInitial}}</span>
        <span v-if="load === 'loading'" class="inline-flex items-center"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-notch" class="mr-2 mt-0.5 animate-spin w-3 h-3 svg-inline--fa fa-circle-notch fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>
          {{onLoad}}</span>
        <span v-if="load === 'success'" class="inline-flex items-center"><svg class="mr-1 w-4 h-4 animate-pulse" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
          {{onSuccess}}</span>
        <span v-if="load === 'error'" class="inline-flex items-center"><svg class="mr-1 w-4 h-4 animate-pulse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" /></svg>
          {{onError}}</span>
    </button>
</template>

<script>
export default {
  name: 'AnimatedButton',
  computed: {
    load() {
      return this.loadState;
    },
  },
  props: {
    onInitial: {
      type: String,
      default: 'Speichern',
    },
    buttonClass: {
      type: String,
    },
    onLoad: {
      type: String,
      default: 'Laden...',
    },
    onSuccess: {
      type: String,
      default: 'Gespeichert!',
    },
    onError: {
      type: String,
      default: 'Fehlgeschlagen!',
    },
    pause: {
      type: Number,
      default: 5000,
    },
    initialClass: {
      type: String,
      default: 'bg-blue-500 hover:bg-blue-700',
    },
    loadClass: {
      type: String,
      default: 'bg-blue-500 cursor-wait',
    },
    errorClass: {
      type: String,
      default: 'bg-red-500 cursor-wait',
    },
    successClass: {
      type: String,
      default: 'bg-green-500 cursor-wait',
    },
  },
  data() {
    return {
      loadState: 'initial',
    };
  },
  watch: {
    loadState: {
      immediate: true,
      handler(value) {
        if (value === 'success' || value === 'error') {
          setTimeout(() => {
            this.loadState = 'initial';
            this.$emit('reset', 'initial');
          }, this.pause);
        }
      },
    },
  },
};
</script>

<style scoped>

</style>
