<template>
  <div class="flex-1 flex flex-col block h-full w-full">
    <div class="flex-grow flex flex-col w-full min-h-24 overflow-auto">
      <div v-for="activity in activities" :key="activity.id"
          class="border-b m-2 bg-blue-500 text-white p-2 rounded-lg">
        <span class="text-xs block">
          {{ activity.created_at | luxon({input: 'iso', output: 'dd.MM.yyyy HH:mm:ss'}) }}
          von {{activity.user.username}}
        </span>
        <span class="text-xs">
          {{ activity.description }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Activities',
  props: {
    cardId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activities: [],
    };
  },
  methods: {
    activitiesCard() {
      this.$axios.get(`activities/card/${this.cardId}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.activities = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
  },
  mounted() {
    this.activitiesCard();
  },
};
</script>

<style scoped>

</style>
