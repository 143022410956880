<template>
  <div class="flex-1 flex flex-col h-full">
    <div class="flex-grow flex flex-col min-h-24 overflow-auto z-10 border-b pb-5"
         ref="commentcontent">
      <FormulateInput
        class="w-1/2"
        type="date"
        name="sample"
        :label="'Fällig: ('+getDueDays+')'" label-class="-mt-3 absolute tracking-wider
                      px-1 uppercase text-xs bg-white text-gray-600 px-1"
        input-class="py-1 px-1 text-gray-900 outline-none block h-full w-full"
        wrapper-class="border focus-within:border-blue-500 focus-within:text-blue-500 self-start
                      transition-all duration-500 relative rounded p-1 mt-5 mb-2 z-0"
        :value="slotCard.due_date | luxon({ input: 'sql', output: 'yyyy-MM-dd' })"
        v-model="slotCard.due_date"
      />
      <div class="h-16 mb-3">
        <v-swatches v-model="slotCard.color" class="absolute z-50 w-1/2" shapes="circles">
          <InputCom slot="trigger" name="color" label="Farbe" v-model="slotCard.color"
                    class="w-5/6" :val="slotCard.color"></InputCom>
          <div slot="trigger" class="h-6 w-6 rounded-xl right-20 top-7 absolute"
               :style="{backgroundColor: slotCard.color}"></div>
        </v-swatches>
      </div>
      <div class="h-16 mb-3">
      <InputCom slot="trigger" name="card_group_name"
                label="Kartengruppe" v-model="slotCard.card_group_name"
                class="w-5/6" :val="slotCard.card_group_name"></InputCom>
      </div>
        <AnimatedButton
          @click.native="updateCard('button-update')"
          class="self-start"
          ref="button-update"
          on-initial="Absenden"
          :pause="750"
        ></AnimatedButton>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import InputCom from '@/components/Util/InputCom.vue';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';

export default {
  name: 'CardSettings',
  components: { AnimatedButton, InputCom, VSwatches },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDueDays() {
      return this.$luxon(this.card.due_date, 'relative');
    },
  },
  data() {
    return {
      message: '',
      slotCard: {},
    };
  },
  methods: {
    updateCard(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`cards/${this.slotCard.id}`, this.slotCard,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          console.log(response.data.data);
          this.$emit('updateCard', response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.slotCard = { ...this.card };
  },
};
</script>

<style scoped>

</style>
