<template>
  <div class="mx-auto h-full">
<!--    <button class="ml-7 bg-blue-600 py-1 px-3 rounded-3xl text-white">-->
<!--      Neue Karte</button>-->
    <div class="flex flex-col h-full w-full mx-auto pb-2 px-6 mb-10 main">
      <div class="flex-shrink mb-2 overscroll-contain">
        <FormulateInput type="text" label="Suche" label-class="-mt-3 absolute
    tracking-wider px-1 uppercase text-xs bg-white text-gray-600 px-1"
              input-class="py-1 px-1 text-gray-900 outline-none block h-full w-full"
              wrapper-class="border focus-within:border-blue-500 focus-within:text-blue-500
                    transition-all duration-500 relative rounded p-1 mt-5 z-0 w-48 float-right"
                        v-model="searchString"
                        @keyup.enter="search"
        />
      </div>
      <div class="flex-1 flex flex-row h-96 w-full overflow-y-hidden pb-2">
        <div
          v-for="column in columns"
          :key="column.id"
          class="pr-3 pl-0.5 py-3 mr-4 bg-gray-100 rounded-lg h-full"
          :class="{'w-5': !column.is_open, 'column-width': column.is_open}"
        >
          <Column :column="column" :cards="column.cards" class="h-full"
                  :statusList="statusList"
                  @cardchange="updateCard"
                  @reload="get"
                  @loadCard="(value) => loadCard(value)"
          ></Column>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Column from '@/components/Column.vue';
// import draggable from 'vuedraggable';
// import TaskCard from '../components/TaskCard.vue';

export default {
  name: 'Home',
  components: {
    Column,
  },
  computed: {
    userState() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      modal: false,
      columns: [],
      columnsUntouched: [],
      cards: [],
      statusList: [],
      searchString: '',
    };
  },
  methods: {
    get() {
      this.$axios.get('columns/profiles',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.columns = response.data.data;
          this.columnsUntouched = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    statusIndex() {
      this.$axios.get('status',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.statusList = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    search() {
      this.columns = JSON.parse(JSON.stringify(this.columnsUntouched));
      if (this.searchString === '') {
        this.columns = { ...this.columnsUntouched };
        this.get();
      }
      const resultColumns = [];
      this.columns.forEach((col) => {
        const resultCol = JSON.parse(JSON.stringify(col));
        resultCol.cards = [];
        col.cards.forEach((card) => {
          if (card.name.toLowerCase().includes(this.searchString.toLowerCase())
              && !card.card_group) {
            resultCol.cards.push(card);
          }
          if (card.card_group) {
            card.card_group.cards.forEach((childCard) => {
              if (childCard.name.toLowerCase().includes(this.searchString.toLowerCase())) {
                resultCol.cards.push(card);
              }
            });
          }
        });
        resultColumns.push(resultCol);
      });
      this.columns = resultColumns;
    },
    // getCards() {
    //   this.$axios.get('http://homestead.test/api/cards')
    //     .then((response) => {
    //       this.cards = response.data.data;
    //     })
    //     .catch(() => {
    //       // console.log(error);
    //     });
    // },
    getCardsForColumn(col) {
      const columnCards = [];
      for (let i = 0; i < this.cards.length; i += 1) {
        if (this.cards[i].column_id === col.id) {
          columnCards.push(this.cards[i]);
        }
      }
      return columnCards;
    },
    updateCard(card) {
      for (let i = 0; i < this.columns.length; i += 1) {
        for (let j = 0; j < this.columns[i].cards.length; j += 1) {
          if (card.id === this.columns[i].cards.id
            && card.column_id !== this.columns[i].id) {
            this.columns[i].cards.splice(j, 1);
          } else if (card.id === this.columns[i].cards.id
            && card.column_id === this.columns[i].id) {
            this.columns[i].cards.splice(j, 1, this.columns[i].cards[j]);
          }
        }
      }
    },
    loadCard(card) {
      this.$axios.get(`cards/${card.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          card = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
  },
  mounted() {
    this.get();
    this.statusIndex();
    window.Echo.channel('column.deleted').listen('ColumnDeleted', () => {
      this.get();
    });
  },
  watch: {
    userState() {
      this.get();
    },
  },
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 400px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.sortable-fallback{
  /*visibility:hidden;*/
}
</style>
