import Vue from 'vue';
import axios from 'axios';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast, {
  position: 'bottom-right',
  dismissible: true,
});

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    authToken: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    authToken(state) {
      return state.authToken;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_AUTH_TOKEN(state, value) {
      state.authToken = value;
    },
  },

  actions: {
    async signIn({ dispatch, commit }, credentials) {
      let loadState = 'loading';
      await axios.post('login', credentials).then((r) => {
        commit('SET_AUTH_TOKEN', r.data.access_token);
        Vue.$toast.open({
          message: 'Erfolgreich angemeldet!',
          type: 'success',
        });
        loadState = dispatch('me');
      }).catch((error) => {
        Vue.$toast.open({
          message: error.response.data.message,
          type: 'error',
        });
        loadState = 'error';
      });
      return loadState;
    },

    async signOut({ dispatch, getters }) {
      await axios.post('logout', {}, { headers: { Authorization: `Bearer ${getters.authToken}` } })
        .then(() => {
          Vue.$toast.open({
            message: 'Abgemeldet',
            type: 'info',
          });
        })
        .catch((error) => {
          Vue.$toast.open({
            message: error,
            type: 'error',
          });
        });

      return dispatch('me');
    },

    me({ commit, getters }) {
      axios.get('user', { headers: { Authorization: `Bearer ${getters.authToken}` } }).then((response) => {
        commit('SET_AUTHENTICATED', true);
        commit('SET_USER', response.data);
        return 'success';
      }).catch(() => {
        commit('SET_AUTH_TOKEN', null);
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
        return 'error';
      });
    },
  },
};
