<template>
  <div
    class="px-3 h-6 rounded-full text-xs font-semibold flex items-center"
    :class="[
      color === 'red' ? 'bg-red-100 text-red-700': '',
      color === 'yellow' ? 'bg-yellow-100 text-yellow-700': '',
      color === 'blue' ? 'bg-blue-100 text-blue-700': '',
     ]"
  >
    <span class="w-2 h-2 rounded-full mr-1" :class="[
      color === 'red' ? 'bg-red-400': '',
      color === 'yellow' ? 'bg-yellow-400': '',
      color === 'blue' ? 'bg-blue-400': '',
      ]"></span>
    <span>
      <slot></slot>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'teal',
    },
  },
  computed() {
  },
};
</script>
