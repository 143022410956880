<template>
<div class="flex-1 flex flex-col block h-full w-full">
  <div class="flex-none flex w-full">
    <FormulateInput type="text" label="Eintrag hinzufügen" label-class="-mt-3 absolute
    tracking-wider px-1 uppercase text-xs bg-white text-gray-600 px-1"
                    input-class="py-1 px-1 text-gray-900 outline-none block h-full w-full"
                    wrapper-class="border focus-within:border-blue-500 focus-within:text-blue-500
                    transition-all duration-500 relative rounded p-1 mt-5 z-0"
                    v-model="addItem.name"
    />
  <FormButton @click.native="activeItem = {};store('button-add');"
              :ref="'button-add'"
              :pause="750"
              label-initial="Hinzufügen"
              class="mt-6 ml-2 h-8"
              :disabled="addItem.name.length === 0">
  </FormButton>
<!--    <AnimatedButton @click.native="store('button-add-'+parentId)"-->
<!--                    on-initial="Hinzufügen"-->
<!--                    :ref="'button-add-'+parentId"-->
<!--                    :pause="750"-->
<!--                    class="mt-6 ml-2 h-8">-->
<!--    </AnimatedButton>-->
  </div>
  <div class="flex-grow flex flex-col w-full min-h-24 overflow-auto z-10 border-b mt-2">
    <draggable
      tag="ul"
      handle=".handle"
      :list="orderedList"
      :animation="200"
      drag-class="drag-column"
      ghost-class="ghost-column"
      class="pr-2"
      :move="onMove"
      @end="endMove"
      :forceFallback="true"
    >
        <li v-for="item in orderedList" :key="item.id"
          class="border px-2 py-1 mt-2 bg-gray-100">
          <span v-if="editItem.id !== item.id" class="flex items-center ">
            <svg class="w-4 h-4 handle cursor-move" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" /></svg>
            <span @click="editItem = item; activeItem = item;" class="ml-2 w-full"
                  :class="[item.is_done ? 'line-through' : '']">
              {{item.name}}
            </span>
  <!--              <svg @click="activeItem = item" class="h-4 w-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>-->
<!--              <IconButton @click.native="editItem = item; activeItem = item;"-->
<!--                          button-type="edit">-->
<!--              </IconButton>-->
            <FormulateInput
              input-class="ml-2 transform scale-150"
              label=""
              type="checkbox"
              v-model="item.is_done"
              @change.native="callUpdate(item)"
            />
          </span>
          <span v-if="editItem.id === item.id" >
             <FormulateInput type="text"
                             label="" label-class="-mt-3 absolute
                             tracking-wider px-1 uppercase text-xs bg-white text-gray-600 px-1"
                             input-class="py-1 px-1 text-gray-900 outline-none block h-full w-full"
                             wrapper-class="border bg-white focus-within:border-blue-500
                             focus-within:text-blue-500 transition-all duration-500 relative
                             rounded p-1 z-0"
                             v-model="slotItem.name"
             />
            <span class="flex justify-between">
              <FormButton @click.native="update('button-update-'+item.id)"
                            :ref="'button-update-'+item.id"
                            :pause="3000"
                            label-initial="Speichern"
                            class="mt-1 h-8 max-w-max"
                            :disabled="editItem.name.length === 0">
                </FormButton>
                <FormButton @click.native="destroy('button-delete-'+item.id)"
                                        :ref="'button-delete-'+item.id"
                                        initial-class="bg-red-500 cursor-pointer"
                                        :pause="3000"
                                        label-initial="Entfernen"
                                        class="mt-1 h-8 max-w-max">
                </FormButton>
            </span>
          </span>
        </li>
      </draggable>
  </div>
</div>
</template>

<script>
import draggable from 'vuedraggable';
// import IconButton from '@/components/Util/IconButton.vue';
import FormButton from '@/components/Util/FormButton.vue';

export default {
  name: 'List',
  components: {
    // IconButton,
    FormButton,
    draggable,
  },
  computed: {
    orderedList() {
      const list = this.localList;
      return list.sort((a, b) => a.position - b.position);
    },
    itemEditState() {
      if (typeof this.activeItem.state !== 'undefined') {
        return this.activeItem.state;
      }
      return 'initial';
    },
  },
  props: {
    listItems: Array,
    parentId: Number,
    scope: String,
  },
  data() {
    return {
      myModel: '',
      inputValue: '',
      activeItem: {},
      slotItem: {},
      editItem: {},
      addItem: {
        name: '',
      },
      localList: [],
    };
  },
  methods: {
    addToList() {
      this.localList.push({ name: this.inputValue, position: this.localList.length });
      this.inputValue = '';
    },
    onMove(e) {
      this.slotItem = e.draggedContext.element;
    },
    endMove(e) {
      this.slotItem.position = e.newIndex;
      this.reorder(this.slotItem, e.oldIndex);
      this.update();
    },
    reorder(item, oldIndex) {
      /* eslint-disable no-param-reassign */
      this.localList.forEach((i) => {
        if (item.id !== i.id && oldIndex > item.position && i.position >= item.position
          && i.position < oldIndex) {
          i.position = Number(i.position) + 1;
        } else if (item.id !== i.id && oldIndex < i.position && i.position <= item.position
          && i.position > oldIndex) {
          i.position = Number(i.position) - 1;
        }
      });
      item.position = Number(item.position);
      /* eslint-enable no-param-reassign */
    },
    reorderOnRemoved(oldIndex) {
      this.localList.forEach((c) => {
        if (c.position > oldIndex) {
          // eslint-disable-next-line no-param-reassign
          c.position = parseInt(c.position, 10) - 1;
        }
      });
    },
    store(button) {
      this.slotItem.name = this.addItem.name;
      this.slotItem.parent_id = this.parentId;
      this.slotItem.is_done = false;
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].buttonState = 'loading';
      this.$axios.post(this.scope, this.slotItem,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].buttonState = 'success';
          this.localList.push(response.data.data);
          this.addItem = { name: '' };
          this.slotItem = {};
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].buttonState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    update(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button][0].buttonState = 'loading';
      console.log(this.slotItem);
      this.$axios.put(`${this.scope}/${this.slotItem.id}`, this.slotItem,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          if (typeof this.$refs[button] !== 'undefined') { this.$refs[button][0].buttonState = 'success'; }
          this.reorder(this.slotItem, this.slotItem.position);
          this.editItem = {};
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button][0].buttonState = 'error';
          this.$toast.open({
            message: e.response.data,
            type: 'error',
          });
        });
    },
    destroy(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button][0].buttonState = 'loading';
      this.$axios.delete(`${this.scope}/${this.slotItem.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          this.localList.splice(this.slotItem.position, 1);
          this.reorderOnRemoved(this.slotItem.position);
          // this.reorder(this.slotItem, this.slotItem.position);
          // const index = this.localList.findIndex((l) => l.id === this.slotItem.id);
          // this.localList.splice(index, 1);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button][0].buttonState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gelöscht!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button][0].buttonState = 'error';
          this.$toast.open({
            message: error.response.data.error,
            type: 'error',
          });
        });
    },
    async callUpdate(item) {
      await this.setDone(item);
      this.update();
    },
    setDone(item) {
      this.slotItem = { ...item };
      // setTimeout(() => {
      //   this.update();
      // }, 50);
    },
  },
  watch: {
    activeItem: {
      immediate: true,
      deep: true,
      handler(value) {
        this.slotItem = { ...value };
      },
    },
    listItems: {
      immediate: true,
      handler(value) {
        this.localList = value;
      },
    },
  },
};
</script>

<style scoped>
.ghost-column {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}

.drag-column {
  opacity: 1;
}
.tooltip {
  z-index: 40;
}
.popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(0,0,0, .1);
  z-index: 40;
}
.popover {
  z-index: 40;
  color: #f9f9f9;
}

.popover-arrow {
  border-color: #f9f9f9;

}
</style>
