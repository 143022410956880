<template>
  <div class="flex-1 flex flex-col block h-full w-full">
    <div class="flex-grow flex flex-col w-full min-h-24 overflow-auto z-10 border-b pb-5"
         style="background-color: #DAD3CC"
         ref="commentcontent">
      <div v-if="comments.length === 0 && orderComments.length === 0"
        class="flex h-6 justify-center ml-2 mt-2 px-3 py-1 w-5/6 inline-block rounded
      text-xs text-center"
           style="background-color: #FCF4CB;">
        Keine Kommentare vorhanden</div>
      <div v-for="orderComment in orderComments" :key="'oc'+orderComment.id"
           class="mt-2 px-3 py-1 ml-2 w-5/6 mb-2 inline-block border rounded text-xs"
           style="backgroundColor: #F2F2F2">
        <span class="text-xs text-gray-500">Plenty-Kommentar</span>
        <span class="block" v-html="orderComment.pl_text"></span>
        <span class="text-right block text-gray-500">
                          {{ orderComment.created_at | luxon(
          {input: 'iso', output: 'dd.MM.yyyy HH:mm:ss' }
          ) }}</span>
      </div>
      <div v-for="comment in comments" :key="'c'+comment.id"
           class="mt-2 px-3 py-1 w-5/6 mb-2 inline-block border rounded text-xs"
           :class="[ comment.user.id === $store.getters['auth/user'].id
                          ? 'ml-10' : 'ml-2',]"
           :style="[comment.user.id === $store.getters['auth/user'].id
                           ? {backgroundColor: '#E2F7CB'} : {backgroundColor: '#F2F2F2'}]"
      >
        <span class="text-xs text-gray-500">{{comment.user.username}}</span>
        <span class="block">{{comment.message}}</span>
        <span class="text-right block text-gray-500">
                          {{ comment.created_at | luxon(
          {input: 'iso', output: 'dd.MM.yyyy HH:mm:ss' }
          ) }}</span>
      </div>
    </div>
    <div class="flex-none mt-1 w-full">
      <div class="w-full md:w-full my-2">
                        <textarea class="bg-gray-100 rounded border border-gray-400 leading-normal
                        resize-none w-full h-15 py-2 px-3 font-medium placeholder-gray-700
                        focus:outline-none focus:bg-white text-xs" name="body"
                                  v-model="message"
                                  required></textarea>
      </div>
      <AnimatedButton
        @click.native="commentStore('button-comment')"
        ref="button-comment"
        on-initial="Absenden"
        :pause="750"
      ></AnimatedButton>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '@/components/Util/AnimatedButton.vue';

export default {
  name: 'Comments',
  components: { AnimatedButton },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    orderComments: {
      type: Array,
      required: true,
    },
    cardId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    commentStore(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('comments', { message: this.message, card_id: this.cardId },
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.comments.push(response.data.data);
          this.message = '';
          this.scrollToEnd();
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    scrollToEnd() {
      setTimeout(() => {
        const content = this.$refs.commentcontent;
        content.scrollTop = content.scrollHeight;
      }, 100);
    },
  },
  mounted() {
    this.scrollToEnd();
  },
};
</script>

<style scoped>

</style>
