<template>
  <div class="rounded-lg flex flex-col h-full z-10">
    <div @click="column.is_open = !column.is_open" class="cursor-pointer pl-2">
            <span v-if="!column.is_open" class="">
              <svg :class="{'whitespace-nowrap origin-left transform rotate-90': !column.is_open}"
                   class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                   xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M9 5l7 7-7 7"></path></svg>
            </span>
      <p
        class="text-gray-700 font-semibold font-sans tracking-wide text-sm"
        :class="{'pl-2 whitespace-nowrap origin-left transform rotate-90': !column.is_open}"
      >
        {{ column.custom_name }}
      </p>
    </div>
    <draggable
      tag="div"
      handle=".handle"
      class="h-full px-2 pb-2 mt-2 rounded-lg overflow-x-hidden"
      :class="{'overflow-y-hidden': !column.is_open, 'overflow-y-scroll': column.is_open }"
      :list="orderedCards"
      :animation="200"
      ghost-class="ghost-card"
      drag-class="drag-card"
      group="card"
      @move="onMove($event)"
      @change="onChange"
      :forceFallback="true"
      dragoverBubble="true"
      v-if="column.is_open"
    >
      <task-card
        v-for="card in orderedCards"
        :key="card.id"
        :card="card"
        :statusList="statusList"
        class="mt-3"
        @setTemplate="updateCard"
        @updateCard="updateCard"
        @archivateCard="removeCard"
        @loadCard="(value) => $emit('loadCard', value)"
      ></task-card>
      <!-- </transition-group> -->
    </draggable>
    <ModalSm ref="modal-confirm" @close="$emit('reload')" custom-class="w-96">
      <template v-slot:header>
        Statusänderung bestätigen
      </template>
      <template v-slot:body>
        Möchten Sie diese Karte wirklich verschieben und den Status ändern?
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="update('button-confirm')"
                        :ref="'button-confirm'"
                        :pause="750"
                        on-initial="Bestätigen"
                        @reset="$refs['modal-confirm'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ModalSm from '@/components/Util/ModalSm.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import TaskCard from './TaskCard.vue';

export default {
  name: 'Column',
  components: {
    TaskCard,
    ModalSm,
    AnimatedButton,
    draggable,
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
    cards: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
    statusList: {
      type: Array,
    },
  },
  computed: {
    orderedCards() {
      const cardList = this.cards;
      //  .filter((c) => c.position >= 0)
      return cardList.sort((a, b) => a.position - b.position);
    },
  },
  data() {
    return {
      activeCard: {},
      localCards: [],
    };
  },
  methods: {
    updateCard(value) {
      const index = this.column.cards.findIndex((c) => c.id === value.id);
      this.column.cards.splice(index, 1, value);
    },
    removeCard(value) {
      const index = this.column.cards.findIndex((c) => c.id === value.id);
      console.log(index);
      this.column.cards.splice(index, 1);
      this.reorderOnRemoved(index);
    },
    update(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`cards/${this.activeCard.id}`, this.activeCard,
        { headers: { 'X-Socket-ID': window.Echo.socketId(), Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          const index = this.column.cards.findIndex((c) => c.id === response.data.data.id);
          this.column.cards.splice(index, 1, response.data.data);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        }).catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    onMove(e) {
      this.activeCard = e.draggedContext.element;
      // this.activeCard = Number(e.draggedContext.element.position);
    },
    onEnd() {
      // tdb
    },
    // onAdd(event, col) {
    //
    // },
    checkConfirm() {
      if (this.column.status !== null && this.column.status.confirmation_required) {
        this.$refs['modal-confirm'].isShown = true;
      } else {
        this.update();
      }
    },
    onChange(e) {
      if ('added' in e) {
        this.activeCard = e.added.element;
        const newIndex = e.added.newIndex >= 0 ? e.added.newIndex : 0;
        this.activeCard.position = Number(newIndex);
        this.activeCard.column_id = this.column.id;
        this.reorderOnAdded(this.activeCard);
        this.checkConfirm();
        // this.emitToParent(this.activeCard);
        this.$forceUpdate();
      } else if ('moved' in e) {
        this.activeCard = e.moved.element;
        this.activeCard.position = Number(e.moved.newIndex);
        this.reorder(this.activeCard, Number(e.moved.oldIndex));
        this.update();
        // this.emitToParent(this.activeCard);
        this.$forceUpdate();
      } else if ('removed' in e) {
        this.reorderOnRemoved(e.removed.oldIndex);
      }
    },
    reorder(card, oldIndex) {
      /* eslint-disable no-param-reassign */
      this.cards.forEach((c) => {
        if (card.id !== c.id && oldIndex > card.position && c.position >= card.position
          && c.position < oldIndex) {
          c.position = parseInt(c.position, 10) + 1;
        } else if (card.id !== c.id && oldIndex < c.position && c.position <= card.position
          && c.position > oldIndex) {
          c.position = parseInt(c.position, 10) - 1;
        }
      });
      card.position = parseInt(card.position, 10);
      /* eslint-enable no-param-reassign */
    },
    reorderOnAdded(card) {
      /* eslint-disable no-param-reassign */
      this.cards.forEach((c) => {
        if (card.id !== c.id && c.position >= card.position) {
          c.position = parseInt(c.position, 10) + 1;
        }
      });
      card.position = Number(card.position);
      /* eslint-enable no-param-reassign */
    },
    reorderOnRemoved(oldIndex) {
      this.cards.forEach((c) => {
        if (c.position > oldIndex) {
          // eslint-disable-next-line no-param-reassign
          c.position = parseInt(c.position, 10) - 1;
        }
      });
    },
    emitToParent(card) {
      this.$emit('cardchange', card);
    },
    getCard(e) {
      if (this.column.id === e.data.column_id) {
        this.$axios.get(`cards/${e.data.id}`,
          { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
          .then((response) => {
            // const index = this.column.cards.findIndex((c) => c.id === response.data.data.id);
            // this.column.cards.splice(index, 1, response.data.data);
            if (response.data.data.position >= 0) {
              console.log('this');
              console.log(response.data);
              this.sync(response.data);
            } else {
              const card = this.cards.find((c) => c.card_group_id
                === response.data.data.card_group_id);
              card.card_group = response.data.data.card_group;
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error.data,
              type: 'error',
            });
          });
      } else {
        this.sync(e);
      }
    },
    sync(e) {
      console.log('sync');
      console.log(e.data);
      if (e.data.position === -1) {
        console.log('hier');
        console.log(e.data);
        this.removeCard(e.data);
      } else {
        const index = this.cards.findIndex((c) => c.id === e.data.id);
        const card = this.cards.find((element) => element.id === e.data.id);
        console.log(index);
        console.log(card);
        if (e.data.column_id === this.column.id) {
          if (index >= 0) {
            this.reorder(e.data, card.position);
            this.cards.splice(index, 1, e.data);
          } else {
            this.reorderOnAdded(e.data);
            this.cards.push(e.data);
          }
          this.emitToParent(e.data);
        } else if (index >= 0) {
          this.cards.splice(index, 1);
          this.reorderOnRemoved(card.position);
        }
      }
      this.$forceUpdate();
    },
  },
  mounted() {
    this.localCards = this.cards;
    window.Echo.channel('card.updated').listen('CardUpdated', (e) => {
      this.getCard(e);
      // this.sync(e);
    });
    window.Echo.channel('card.added').listen('CardAdded', (e) => {
      if (e.data.column_id === this.column.id) {
        e.data.position = this.column.cards.length;
        this.cards.push(e.data);
      }
    });
    window.Echo.channel('card.removed').listen('CardRemoved', (e) => {
      console.log('card removed');
      console.log(e.data);
      if (e.data.column_id === this.column.id) {
        const index = this.cards.findIndex((c) => c.id === e.data.id);
        const card = this.cards.find((element) => element.id === e.data.id);
        this.cards.splice(index, 1);
        this.reorderOnRemoved(card.position);
      }
    });
    // this.index();
  },
  // watch: {
  //   'cards.length': function () {
  //     this.cards.forEach((c) => {
  //       // eslint-disable-next-line no-param-reassign
  //       if (c.column_id !== this.column.id) {
  //         // eslint-disable-next-line no-param-reassign
  //         c.column_id = this.column.id;
  //         this.$emit('endMove', this.activeCard);
  //       }
  //     });
  //     // this.index();
  //   },
  // },
};
</script>

<style scoped>
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
  /*transition: 1s ease;*/
}

.drag-card {
  opacity: 1!important;
}
</style>
