<template>
  <div>
    <div class="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all
              duration-500 relative rounded p-1 mt-5 z-0">
          <label :for="name" class="-mt-4 absolute tracking-wider px-1 uppercase text-xs
          bg-white text-gray-600 px-1">{{ label }}
            <span v-if="isRequired" class="text-red-500">*</span></label>
      <div>
        <input :type="inputType" :name="name" :placeholder="placeholder"
               :autocomplete="autocomplete"
               v-model.trim="$v.inputValue.$model" :class="inputClass"
               @input="$emit('input', inputValue)" class="py-1 px-1 text-gray-900 outline-none
                         block h-full w-full" />
      </div>

    </div>
    <div class="error" v-if="$v.inputValue.$error">
      <span class="d-block" v-if="!$v.inputValue.requiredIf">{{ requiredMsg }}</span>
      <span class="d-block" v-if="!$v.inputValue.minLength">{{ minLengthMsg }}</span>
<!--      <span class="d-block" v-if="!$v.inputValue.mustBeCool">-->
<!--          Eingabe stimmt nicht überein!</span>-->
    </div>
  </div>
</template>

<script>
import { requiredIf, minLength /* helpers */ } from 'vuelidate/lib/validators';

// const contains = (param) => helpers.withParams({ type: 'contains', value: param },
//   (value) => !helpers.req(value) || value === param);

export default {
  name: 'Input',
  props: {
    name: {
      type: String,
      required: true,
      default: 'Input',
    },
    placeholder: {
      type: String,
      default: '',
    },
    val: [String, Number],
    mustBe: String,
    inputClass: String,
    inputType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: 'Mein Label',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    requiredMsg: {
      type: String,
      default: 'Das ist ein Pflichtfeld.',
    },
    minLength: {
      type: Number,
      default: 0,
    },
    minLengthMsg: String,
  },
  data() {
    return {
      inputValue: '',
    };
  },
  validations() {
    return {
      inputValue: {
        // eslint-disable-next-line no-self-compare,func-names
        requiredIf: requiredIf(() => this.isRequired),
        minLength: minLength(4),
        // mustBeCool: contains(this.mustBe),
      },
    };
  },
  mounted() {
  },
  watch: {
    val: {
      immediate: true,
      handler(val) {
        this.inputValue = val;
      },
    },
    '$v.inputValue.$error': {
      handler(value) {
        this.$emit('error', value);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 12px;
}
</style>
