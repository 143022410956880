<template>
  <div class="flex-1 flex flex-col h-full">
    <div class="flex-grow flex flex-col min-h-24 overflow-auto z-10 border-b pb-5"
         ref="cardgroup">
      <div class="h-16 mb-3">
      <InputCom slot="trigger" name="card_group_name"
                label="Kartengruppe" v-model="form.card_group_name"
                class="w-5/6" :val="cardGroup.name"></InputCom>
      </div>
        <AnimatedButton
          @click.native="storeCardGroup('button-store')"
          class="self-start"
          ref="button-store"
          on-initial="Absenden"
          :pause="750"
        ></AnimatedButton>
      <div class="mt-4" v-if="cardGroup.cards">
        <h2 class="text-xl">Karten</h2>
        <div v-for="childCard in cardGroup.cards" :key="childCard.id" class="flex border-b">
          <div class="flex-1">{{ childCard.name }}</div>
          <div class="flex-shrink-0">
            <span @click="activeChildCard = childCard; $refs['modal-delete'].isShown = true;
            deleteErrors = []" v-if="childCard.position === -1"
            class="cursor-pointer text-red-500 hover:text-red-700 inline-flex">
            <svg class="w-4 h-4 mt-0.5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Aus Gruppe entfernen</span></div>
        </div>
      </div>
      <div v-if="card.card_group_id">
         <span @click="$refs['modal-delete-group'].isShown = true;"
                          class="cursor-pointer text-red-500 hover:text-red-700 inline-flex">
            <svg class="w-4 h-4 mt-0.5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Gruppe auflösen</span>
      </div>
    </div>
    <ModalSm ref="modal-delete" customClass="h-30 w-64">
      <template v-slot:header>
        Aus Kartengruppe löschen
      </template>
      <template v-slot:body>
        <p>Möchten Sie die Karte wirklich aus der Kartengruppe löschen?</p>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="deleteFromCardGroup('button-delete-from-card-group')"
                        :ref="'button-delete-from-card-group'"
                        on-initial="Löschen"
                        on-error="Fehlgeschlagen!"
                        on-success="Erfolgreich gelöscht!"
                        initial-class="bg-red-500 hover:bg-red-700"
                        :pause="750"
                        @reset="$refs['modal-delete'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-delete-group" customClass="h-30 w-64">
      <template v-slot:header>
        Kartengruppe auflösen
      </template>
      <template v-slot:body>
        <p>Möchten Sie diese Kartengruppe wirklich auflösen?</p>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="removeCardGroup('button-remove-card-group')"
                        :ref="'button-remove-card-group'"
                        on-initial="Auflösen"
                        on-error="Fehlgeschlagen!"
                        on-success="Erfolgreich aufgelöst!"
                        initial-class="bg-red-500 hover:bg-red-700"
                        :pause="750"
                        @reset="$refs['modal-delete-group'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
  </div>
</template>

<script>
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import InputCom from '@/components/Util/InputCom.vue';
import ModalSm from '@/components/Util/ModalSm.vue';

export default {
  name: 'CardGroup',
  components: { AnimatedButton, InputCom, ModalSm },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDueDays() {
      return this.$luxon(this.card.due_date, 'relative');
    },
  },
  data() {
    return {
      message: '',
      slotCard: {},
      cardGroup: {},
      activeChildCard: {},
      form: {
        id: null,
        group_name: null,
      },
    };
  },
  methods: {
    get() {
      if (this.card.card_group_id) {
        this.$axios.get(`card-groups/${this.card.card_group_id}`,
          { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
          .then((response) => {
            this.cardGroup = response.data;
            this.form.id = this.cardGroup.id;
            this.form.group_name = this.cardGroup.name;
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data,
              type: 'error',
            });
          });
      }
    },
    storeCardGroup(button) {
      if (this.form.group_name === null) {
        this.form.group_name = this.form.card_group_name;
      }
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('card-groups', this.form,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$emit('updateCard', this.card);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
          this.get();
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    deleteFromCardGroup(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.get(`card-groups/${this.activeChildCard.id}/remove-card-group`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
          this.get();
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    removeCardGroup(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.delete(`card-groups/${this.cardGroup.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$emit('close', false);
          this.$toast.open({
            message: 'Erfolgreich aufgelöst!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.get();
    this.slotCard = { ...this.card };
    this.form.card_id = this.card.id;
  },
};
</script>

<style scoped>

</style>
