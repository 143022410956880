<template>
  <div
    class="bg-white shadow rounded px-2 pt-2 pb-2 border-t-8"
    :style="'border-color: ' + card.color"
  >
    <div v-if="!card.card_group_id">
      <div class="flex justify-between items-center border-b">
        <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm pb-1">
          {{ card.name }}
        </p>
        <span class="cursor-move handle">
          <svg class="h-4 w-4 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
        </span>
      </div>
      <div>
        <p v-if="card.order !== null"
           class="text-gray-700 font-semibold font-sans tracking-wide text-xs">
          {{ getStatus(card) }}
        </p>
      </div>
      <div class="flex justify-between items-center mt-2">
        <span></span>
        <span @click="modal = true; activeCard = card;"
              class="cursor-pointer
        text-gray-700 font-semibold font-sans tracking-wide text-xs">
          Karte öffnen</span>
      </div>
      <div class="flex mt-4 justify-between items-center">
      <span class="text-sm text-gray-600">
        Fällig: {{ card.due_date | luxon({ input: "sql", output: "dd.MM.yyyy" }) }}</span>
<!--      <badge :color="dueColor" class="text-xs">-->
<!--        {{ card.due_date | luxon({ input: "sql", output: 'relative', relative: {-->
<!--            style: 'narrow', round: true, unit: 'days',},} )}}</badge>-->
      <badge :color="dueColor" class="text-xs">
        {{ dueText }}</badge>
    </div>
    </div>
    <div v-if="card.card_group_id">
      <div class="flex justify-between items-center border-b">
        <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm pb-1">
          Gruppe {{card.card_group.name}}
        </p>
        <span class="cursor-move handle">
          <svg class="h-4 w-4 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
        </span>
      </div>
      <div>
        <div class="text-gray-700 font-semibold font-sans tracking-wide text-xs mb-2 pb-2 border-b"
           v-for="childCard in card.card_group.cards" :key="childCard.id">
          {{ childCard.name }} <br />
          {{ getStatus(childCard) }}
          <div class="flex justify-between items-center mt-2">
            <span></span>
            <span @click="modal = true; activeCard = childCard;"
                  class="cursor-pointer
        text-gray-700 font-semibold font-sans tracking-wide text-xs">
          Karte öffnen</span>
          </div>
        <div class="flex mt-4 justify-between items-center">
        <span class="text-xs text-gray-600">
        Fällig: {{ card.due_date | luxon({ input: "sql", output: "dd.MM.yyyy" }) }}</span>
          <!--      <badge :color="dueColor" class="text-xs">-->
          <!--        {{ card.due_date | luxon({ input: "sql", output: 'relative', relative: {-->
          <!--            style: 'narrow', round: true, unit: 'days',},} )}}</badge>-->
          <badge :color="dueColor" class="text-xs">
            {{ dueText }}</badge>
        </div>
        </div>
      </div>
    </div>
    <TaskView v-if="modal" :ref="'modal-order'" :card="activeCard" :status="getStatus(activeCard)"
              @close="(value) => modal = value"
              @setTemplate="(value) => $emit('setTemplate', value)"
              @updateCard="(value) => $emit('updateCard', value)"
              @archivateCard="(value) => $emit('archivateCard', value)"
    ></TaskView>
  </div>
</template>
<script>
// import Activities from '@/components/Activities.vue';
// import List from '@/components/admin/List.vue';
import Badge from './Badge.vue';
import TaskView from './TaskView.vue';
// import Modal from './Util/Modal.vue';
// import Comments from './Comments.vue';

export default {
  components: {
    // List,
    // Activities,
    Badge,
    // Modal,
    TaskView,
    // Comments,
  },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    statusList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    dueColor() {
      const now = this.$luxon(Date.now(),
        {
          input: 'millis',
          output: 'yyyy-MM-dd',
        });
      const oneDay = 24 * 60 * 60 * 1000;
      const diffInDays = Math.round((new Date(now) - new Date(this.card.due_date)) / oneDay);
      if (diffInDays > 0) {
        return 'red';
      }
      if (diffInDays <= 0 && diffInDays >= -3) {
        return 'yellow';
      }
      return 'blue';
    },
    dueText() {
      const now = this.$luxon(Date.now(),
        {
          input: 'millis',
          output: 'yyyy-MM-dd',
        });
      const oneDay = 24 * 60 * 60 * 1000;
      const diffInDays = Math.round((new Date(now) - new Date(this.card.due_date)) / oneDay);
      if (diffInDays > 0) {
        if (diffInDays === 1) {
          return 'gestern';
        }
        return `vor ${diffInDays} Tagen`;
      }
      if (diffInDays < 0) {
        if (diffInDays === -1) {
          return 'morgen';
        }
        return `in ${Math.abs(diffInDays)} Tagen`;
      }
      return 'heute';
    },
    statusOptions() {
      const options = [];
      this.statusList.forEach((s) => {
        options.push({ value: s.status, label: `${s.status} - ${s.name}` });
      });
      options.splice(0, 1);
      return options;
    },
    getDueDays() {
      return this.$luxon(this.card.due_date, 'relative');
    },
  },
  data() {
    return {
      activeTab: 'comments',
      modal: false,
      activeCard: {},
      order: {},
      localStatusList: [],
    };
  },
  methods: {
    openTask() {
      this.$emit('modal', this.modal);
    },
    orderIndex() {

    },
    getStatus(card) {
      // eslint-disable-next-line no-unused-vars
      let statustext = '';
      this.localStatusList.forEach((s) => {
        if (card.order !== null && s.status === card.order.pl_status) {
          statustext = `Status: ${s.status} - ${s.name}`;
        }
      });
      return statustext;
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
  watch: {
    statusList: {
      immediate: true,
      handler(value) {
        this.localStatusList = value;
      },
    },
  },
};
</script>
<style>
input:checked + svg {
  display: block;
}

.v-select .vs__selected-options{
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.v-select .vs__clear { visibility: hidden; }
.v-select .vs__open-indicator {
  cursor: pointer;
}

.v-select .vs__dropdown-menu {
  position: absolute!important;
  box-sizing: border-box;
}

.v-select .vs__dropdown-toggle {
  border: none!important;
}
</style>
