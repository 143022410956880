import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component() {
      return import('../views/Login.vue');
    },
    meta: {
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component() {
      return import('../views/Admin.vue');
    },
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component() {
      return import('../views/Settings.vue');
    },
    meta: {
      roles: ['admin', 'user'],
    },
  },
  {
    path: '/reklamation',
    name: 'Reklamation',
    component() {
      return import('../views/Reklamation.vue');
    },
    meta: {
      roles: ['admin', 'user'],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login' && !store.getters['auth/authenticated']) {
    next({ name: 'Login' });
  } else if (to.name !== 'Login' && store.getters['auth/authenticated']) {
    if (!to.meta.roles.includes(store.getters['auth/user'].role)) {
      console.log('toHome');
      next({ name: 'Home' });
    }
    next();
  } else {
    next();
  }
});

export default router;
