<template>
    <transition name="fade" v-if="isShown">
      <div class="fixed inset-0 z-50 static">
        <div class="bg-filter bg-black opacity-25 fixed
        inset-0 w-full h-full z-20">
        </div>
        <main class="flex flex-col px-10 py-10 z-50">
          <transition name="fade-up-down">
            <div class="modal-wrapper inline-block items-center z-50">
              <div class="modal max-w-screen mx-auto bg-white z-20
              max-h-screen shadow-lg rounded relative" :class="customClass"
                   :style="customStyle">
                <div class="modal-header p-3 text-gray-900 rounded-t border-b flex justify-between
                    items-center">
                  <slot name="header"></slot>
                  <svg @click="close()" class="h-4 w-4 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
                </div>
                <div class="modal-body flex-shrink bg-white p-3 w-full z-10">
                  <slot name="body"></slot>
                </div>
                <div class="modal-footer bg-white py-3 px-5 border-t text-right">
                  <slot name="footer"></slot>
                  <button class="bg-gray-500 hover:bg-gray-700 rounded text-gray-100 px-3 py-1
            hover:shadow-inner align-middle
            transition-all duration-300"
                          @click="close()">Schließen</button>
                </div>
              </div>
            </div>
          </transition>
        </main>
      </div>
    </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'ModalSm',
  directives: {
    ClickOutside,
  },
  props: {
    customClass: String,
    customStyle: String,
    show: Boolean,
  },
  data() {
    return {
      isShown: false,
    };
  },
  methods: {
    close() {
      this.isShown = !this.isShown;
      this.$emit('close', false);
    },
    onEscapeKeyUp(event) {
      if (event.keyCode === 27 && this.isShown) {
        this.isShown = false;
      }
    },
  },
  mounted() {
    this.isShown = this.show;
    // prevent click outside event with popupItem.
    this.popupItem = this.$el;
  },
  beforeMount() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
  },
};
</script>

<style scoped>
main {
  max-height: 100vh;
}

/*.modal-body{*/
/*  max-height: 100vh;*/
/*}*/
.bg-gray-800-opacity {
  background-color: #2D374850;
}

/*@media screen and (max-width: 768px) {*/
/*  .modal-body {*/
/*    max-height: 400px;*/
/*  }*/
/*}*/

/*.modal-body {*/
/*  max-height: 400px;*/
/*}*/

/* animation for vue transition tag */

.fade-up-down-enter-active {
  transition: all 0.3s ease;
}

.fade-up-down-leave-active {
  transition: all 0.3s ease;
}

.fade-up-down-enter {
  transform: translateY(10%);
  opacity: 0;
}

.fade-up-down-leave-to {
  transform: translateY(10%);
  opacity: 0;
}

.fade-enter-active {
  -webkit-transition: opacity 2s;
  transition: opacity .3s;

}

.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
