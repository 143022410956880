<template>
  <div>
    <Modal
      ref="modal-order1"
      customClass="w-full h-full border-t-8"
      @close="isShown = false; $emit('close', false)"
      :customStyle="'border-color: ' + card.color">
      <template v-slot:header>
        <div class="flex flex-row">
          <div class="pl-5">{{ card.name }}</div>
          <div class="pl-1">| {{status}}</div>
          <div class="pl-1"> |
        Fällig: {{ card.due_date | luxon({ input: "sql", output: "dd.MM.yyyy" }) }}</div>
          <badge :color="dueColor" class="ml-1">
            {{ dueText }}</badge>
        </div>
      </template>
      <template v-slot:body>
        <div class="flex flex-col h-full px-5">
          <div class="items-stretch h-full">
            <div class="flex flex-row h-full">
              <div class="flex-1 flex flex-col h-full border-r">
                <!-- Adressen -->
                <div class="flex flex-row flex-none">
                  <div class="flex-1">
                    <div class="flex-shrink-0 text-gray-700 font-semibold font-sans tracking-wide
                  border-b mt-0.5">Rechnungsadresse:
                    </div>
                    <p class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{ card.order.order_addresses[0].pl_name1 }}
                      {{ card.order.order_addresses[0].pl_name2 }}
                      {{ card.order.order_addresses[0].pl_name3 }}
                      {{ card.order.order_addresses[0].pl_name4 }}
                    <p class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{
                        [card.order.order_addresses[0].pl_address1,
                          card.order.order_addresses[0].pl_address2,
                          card.order.order_addresses[0].pl_address3,
                          card.order.order_addresses[0].pl_address4].join(' ')
                          .trim()
                      }}</p>
                    <p class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{ card.order.order_addresses[0].pl_postalcode }}
                      {{ card.order.order_addresses[0].pl_town }}</p>
                    <p v-for="contact in card.order.order_addresses[0].order_address_contacts"
                       :key="contact.id"
                       class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      030/134844</p>
                  </div>
                  <div class="flex-1">
                    <div class="flex-shrink-0 text-gray-700 font-semibold font-sans tracking-wide
                border-b mt-0.5">Lieferadresse:
                    </div>
                    <p class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{ card.order.order_addresses[1].pl_name1 }}
                      {{ card.order.order_addresses[1].pl_name2 }}
                      {{ card.order.order_addresses[1].pl_name3 }}
                      {{ card.order.order_addresses[1].pl_name4 }}
                    <p class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{
                        [card.order.order_addresses[1].pl_address1,
                          card.order.order_addresses[1].pl_address2,
                          card.order.order_addresses[1].pl_address3,
                          card.order.order_addresses[1].pl_address4].join(' ')
                          .trim()
                      }}
                    </p>
                    <p class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{ card.order.order_addresses[1].pl_postalcode }}
                      {{ card.order.order_addresses[1].pl_town }}</p>
                    <p v-for="contact in
                                card.order.order_addresses[1].order_address_contacts"
                       :key="contact.id"
                       class="text-gray-700 font-sans tracking-wide text-sm pr-2">
                      {{ contact.pl_value }}</p>
                  </div>
                </div>
                <!-- Artikel -->
                  <div class="flex-1 flex flex-col h-0.5 mt-5">
                    <ProductTable :card="card"></ProductTable>
                </div>
              </div>
              <div class="flex-1 flex flex-col h-full border-r">
                <div class="flex flex-none pl-5 overflow-x-auto">
                  <div @click="activeTab1 = 'todo';" class="flex-shrink-0 text-gray-700
                font-semibold font-sans tracking-wide mt-0.5 px-2 cursor-pointer"
                       :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
                                      activeTab1 == 'todo'}">
                    ToDo-Liste
                  </div>
                  <div @click="activeTab1 = 'activities';" class="flex-shrink-0 text-gray-700
                font-semibold font-sans tracking-wide mt-0.5 px-2 cursor-pointer"
                       :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
                                      activeTab1 == 'activities'}">
                    Aktivitäten
                  </div>
                </div>
                <div v-show="activeTab1 === 'todo'"
                     class="flex-1 p-2 h-0.5">
                  <List :list-items="card.list_items" :parent-id="card.id"
                        scope="listitems"
                  ></List>
                </div>
                <div v-show="activeTab1 === 'activities'"
                     class="flex-1 p-2 h-0.5">
                  <Activities :card-id="card.id"></Activities>
                </div>
              </div>
              <div class="flex-1 flex flex-col h-full w-0.5">
                <div class="flex flex-none pl-5 overflow-x-auto">
                  <div @click="activeTab = 'comments';" class="flex-shrink-0 text-gray-700
                font-semibold font-sans tracking-wide mt-0.5 px-2 cursor-pointer"
                       :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
                                      activeTab == 'comments'}">
                    Kommentare
                  </div>
                  <div @click="activeTab = 'cardSettings';" class="flex-shrink-0 text-gray-700
                font-semibold font-sans tracking-wide mt-0.5 px-2 cursor-pointer"
                       :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
                                      activeTab == 'cardSettings'}">
                    Einstellungen
                  </div>
                  <div @click="activeTab = 'cardGroup';" class="flex-shrink-0 text-gray-700
                font-semibold font-sans tracking-wide mt-0.5 px-2 cursor-pointer"
                       :class="{'text-blue-500 border-b-2 font-medium border-blue-500':
                                      activeTab == 'cardGroup'}">
                    Kartengruppe
                  </div>
<!--                  <div @click="activeTab = 'otherCards';" class="flex-shrink-0 text-gray-700-->
<!--                font-semibold font-sans tracking-wide mt-0.5 px-2 cursor-pointer"-->
<!--                       :class="{'text-blue-500 border-b-2 font-medium border-blue-500':-->
<!--                                      activeTab == 'otherCards'}">-->
<!--                    Weitere Karten-->
<!--                  </div>-->
                </div>
                <div v-show="activeTab === 'comments'"
                     class="flex-1 p-2 h-0.5">
                  <Comments :comments="card.comments" :order-comments="card.order.order_comments"
                            :card-id="card.id"></Comments>
                </div>
                <div v-show="activeTab === 'cardSettings'"
                     class="flex-1 p-2 h-0.5">
                  <CardSettings :card="card"
                                @updateCard="(value) => $emit('updateCard', value)">
                  </CardSettings>
                </div>
                <div v-show="activeTab === 'cardGroup'"
                     class="flex-1 p-2 h-0.5">
                  <CardGroups :card="card" @close="(value) => $emit('close', false)"
                                @updateCard="(value) => $emit('updateCard', value)">
                  </CardGroups>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="relative">
          <a href="#" @click.prevent="showOptions = !showOptions">Optionen anzeigen</a>
          <div class="absolute -top-36 px-3 shadow bg-white rounded border w-48 z-20"
               v-if="showOptions">
            <ul class="divide-y">
              <li class="leading-8 hover:text-gray-500">
                <a href="#" v-if="card.position >= 0"
                   @click.prevent="$refs['modal-template-'+card.id].isShown = true;
                   showOptions = false">
                  Vorlage anwenden</a>
                <span v-if="card.position < 0" class="line-through">Vorlage anwenden</span>
              </li>
              <li class="leading-8 hover:text-gray-500">
                <a href="#" v-if="!card.card_group_id"
                   @click.prevent="$refs['modal-return-'+card.id].isShown = true;
                   showOptions = false;">
                  Reklamation starten</a>
                <span v-if="card.card_group_id" class="line-through">Reklamation starten</span>
              </li>
              <li class="leading-8 hover:text-gray-500">
                <a href="#" v-if="!card.card_group_id"
                   @click.prevent="$refs['modal-split-'+card.id].isShown = true;
                   showOptions = false;">
                  Karte aufteilen</a>
                <span v-if="card.card_group_id" class="line-through">Karte aufteilen</span>
              </li>
              <li class="leading-8 hover:text-gray-500">
                <a href="#" v-if="!card.card_group_id"
                   @click.prevent="$refs['modal-archivate-'+card.id].isShown = true;
                   showOptions = false;">
                  Karte archivieren</a>
                <span v-if="card.card_group_id" class="line-through">Karte archivieren</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </Modal>
    <ModalSm :ref="'modal-template-'+card.id" custom-class="w-80">
      <template v-slot:header>
        Vorlage anwenden
      </template>
      <template v-slot:body>
        <div class="border focus-within:border-blue-500 focus-within:text-blue-500
              transition-all
              duration-500 relative rounded p-1 mt-5 z-0">
          <label for="status_select" class="-mt-4 absolute tracking-wider px-1 uppercase
                text-xs bg-white text-gray-600">Vorlage</label>
          <div>
            <v-select v-model="activeTemplate" :options="templatesOptions"
                      :reduce="label => label.value"
                      height="20"
                      :appendToBody="true"
                      id="status_select"
                      class="text-sm whitespace-nowrap text-gray-900 outline-none
                         block h-full w-full border-none"
            ></v-select>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="setTemplate('button-template-'+card.id)"
                        :ref="'button-template-'+card.id"
                        :pause="750"
                        on-initial="Anwenden"
                        @reset="$refs['modal-template-'+card.id].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm :ref="'modal-archivate-'+card.id" custom-class="w-80">
      <template v-slot:header>
        Karte archivieren
      </template>
      <template v-slot:body>
        Möchten Sie diese Karte wirklich archivieren?
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="archivateCard('button-archivate-'+card.id)"
                        :ref="'button-archivate-'+card.id"
                        :pause="750"
                        on-initial="Archivieren"
                        @reset="$refs['modal-archivate-'+card.id].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <Modal :ref="'modal-split-'+card.id" custom-class="w-3/6">
      <template v-slot:header>
        Karte aufteilen
      </template>
      <template v-slot:body>
        <div class="flex-1 flex flex-col block h-full w-full">
          <ProductTable :card="card" :selectable="true"
                      @addSlotOrderItems="(value) => slotOrderItems = value"></ProductTable>
        </div>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="splitCard('button-split-'+card.id)"
                        :ref="'button-split-'+card.id"
                        :pause="750"
                        on-initial="Aufteilen"
                        @reset="$refs['modal-split-'+card.id].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </Modal>
    <ModalSm :ref="'modal-return-'+card.id" custom-class="w-3/6">
      <template v-slot:header>
        Reklamation starten
      </template>
      <template v-slot:body>
        <div class="flex-1 flex flex-col block h-full w-full">
          <div class="border focus-within:border-blue-500 focus-within:text-blue-500
              transition-all
              duration-500 relative rounded p-1 mt-5 z-0">
            <label for="status_select" class="-mt-4 absolute tracking-wider px-1 uppercase
                text-xs bg-white text-gray-600">Typ</label>
            <div>
              <v-select v-model="returnOrder.type" :options="['Intern', 'Extern']"
                        height="20"
                        :appendToBody="true"
                        id="type_select"
                        class="text-sm whitespace-nowrap text-gray-900 outline-none
                         block h-full w-full border-none"
              ></v-select>
            </div>
          </div>
          <ReturnProductTable :card="card" :selectable="true" :reasons-options="reasonsOptions"
                              :status-options="statusOptions"
                        @addSlotOrderItems="(value) => slotOrderItems = value"></ReturnProductTable>
        </div>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="returnCard('button-return-'+card.id)"
                        :ref="'button-return-'+card.id"
                        :pause="750"
                        on-initial="Reklamieren"
                        @reset="$refs['modal-return-'+card.id].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
  </div>
</template>

<script>
import List from '@/components/admin/List.vue';
import ModalSm from '@/components/Util/ModalSm.vue';
import CardSettings from '@/components/CardSettings.vue';
import CardGroups from '@/components/CardGroups.vue';
import ProductTable from '@/components/ProductTable.vue';
import ReturnProductTable from '@/components/ReturnProductTable.vue';
import Modal from './Util/Modal.vue';
import Comments from './Comments.vue';
import Activities from './Activities.vue';
import AnimatedButton from './Util/AnimatedButton.vue';
import Badge from './Badge.vue';

export default {
  name: 'TaskView',
  components: {
    ProductTable,
    ReturnProductTable,
    CardSettings,
    CardGroups,
    AnimatedButton,
    ModalSm,
    Modal,
    Badge,
    List,
    Comments,
    Activities,
  },
  props: {
    card: Object,
    status: String,
  },
  computed: {
    orderItems() {
      const items = [];
      this.card.order_items.forEach((o) => {
        if (o.pl_type !== 6) {
          // eslint-disable-next-line no-param-reassign
          o.pl_name = o.pl_name.replace(/\s\|\s/g, '<br />');
          items.push(o);
        }
      });
      return items;
    },
    getDueDays() {
      return this.$luxon(this.card.due_date, 'relative');
    },
    dueColor() {
      const now = this.$luxon(Date.now(),
        {
          input: 'millis',
          output: 'yyyy-MM-dd',
        });
      const oneDay = 24 * 60 * 60 * 1000;
      const diffInDays = Math.round((new Date(now) - new Date(this.card.due_date)) / oneDay);
      if (diffInDays > 0) {
        return 'red';
      }
      if (diffInDays <= 0 && diffInDays >= -3) {
        return 'yellow';
      }
      return 'blue';
    },
    dueText() {
      const now = this.$luxon(Date.now(),
        {
          input: 'millis',
          output: 'yyyy-MM-dd',
        });
      const oneDay = 24 * 60 * 60 * 1000;
      const diffInDays = Math.round((new Date(now) - new Date(this.card.due_date)) / oneDay);
      if (diffInDays > 0) {
        if (diffInDays === 1) {
          return 'gestern';
        }
        return `vor ${diffInDays} Tagen`;
      }
      if (diffInDays < 0) {
        if (diffInDays === -1) {
          return 'morgen';
        }
        return `in ${Math.abs(diffInDays)} Tagen`;
      }
      return 'heute';
    },
    templatesOptions() {
      const options = [];
      this.templates.forEach((t) => {
        options.push({
          value: t.id,
          label: t.name,
        });
      });
      return options;
    },
    reasonsOptions() {
      const options = [];
      this.returnReasons.forEach((t) => {
        options.push({
          value: t.id,
          label: t.name,
        });
      });
      return options;
    },
    statusOptions() {
      const options = [];
      this.returnStatus.forEach((t) => {
        options.push({
          value: t.id,
          label: t.name,
        });
      });
      return options;
    },
  },
  data() {
    return {
      slotCard: {},
      isShown: false,
      activeTab1: 'todo',
      activeTab: 'comments',
      templates: [],
      activeTemplate: null,
      showOptions: false,
      slotOrderItems: [],
      returnOrder: {},
      returnReasons: [],
      returnStatus: [],
    };
  },
  methods: {
    getReturnReasons() {
      this.$axios.get('order-return-reasons',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.returnReasons = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReturnStatus() {
      this.$axios.get('order-return-status',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.returnStatus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTemplates() {
      this.$axios.get('templates/active',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.templates = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setTemplate(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.get(`cards/${this.card.id}/template/${this.activeTemplate}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$emit('setTemplate', response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    archivateCard(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.get(`cards/${this.card.id}/archivate`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$emit('archivateCard', response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
    splitCard(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      if (JSON.stringify(this.orderItems.sort((a, b) => a.id - b.id))
        !== JSON.stringify(this.slotOrderItems.sort((a, b) => a.id - b.id))
        && this.slotOrderItems.length > 0) {
        this.$axios.post(`cards/${this.card.id}/split`, { orderItems: this.slotOrderItems },
          { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
          .then((response) => {
            if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
            this.$emit('splitCard', response.data.data);
            this.$toast.open({
              message: 'Erfolgreich gespeichert!',
              type: 'success',
            });
          })
          .catch((error) => {
            if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
            this.$toast.open({
              message: error.response.data,
              type: 'error',
            });
          });
      } else {
        if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
        this.$toast.open({
          message: 'Karte kann nicht geteilt werden!',
          type: 'error',
        });
      }
    },
    returnCard(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      if (JSON.stringify(this.orderItems.sort((a, b) => a.id - b.id))
        !== JSON.stringify(this.slotOrderItems.sort((a, b) => a.id - b.id))
        && this.slotOrderItems.length > 0) {
        this.$axios.post('order-returns', { orderItems: this.slotOrderItems, returnType: this.returnOrder.type, orderId: this.card.order.id },
          { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
          .then((response) => {
            if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
            this.$emit('returnCard', response.data.data);
            this.$toast.open({
              message: 'Erfolgreich gespeichert!',
              type: 'success',
            });
          })
          .catch((error) => {
            if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
            this.$toast.open({
              message: error.response.data,
              type: 'error',
            });
          });
      } else {
        if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
        this.$toast.open({
          message: 'Karte kann nicht reklamiert werden!',
          type: 'error',
        });
      }
    },
  },
  beforeMount() {
    this.getTemplates();
    this.getReturnReasons();
    this.getReturnStatus();
  },
  mounted() {
    this.$refs['modal-order1'].isShown = true;
  },
  watch: {},
};
</script>

<style scoped>

/*.modal-body {*/
/*  max-height: 500px;*/
/*}*/

.bg-gray-800-opacity {
  background-color: #2D374850;
}

@media screen and (max-width: 768px) {
  /*.modal-body {*/
  /*  max-height: 400px;*/
  /*}*/
}

/* animation for vue transition tag */

.fade-up-down-enter-active {
  transition: all 0.3s ease;
}

.fade-up-down-leave-active {
  transition: all 0.3s ease;
}

.fade-up-down-enter {
  transform: translateY(10%);
  opacity: 0;
}

.fade-up-down-leave-to {
  transform: translateY(10%);
  opacity: 0;
}

.fade-enter-active {
  -webkit-transition: opacity 2s;
  transition: opacity .3s;

}

.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
